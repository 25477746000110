<template>
  <div v-if="!loadingComponent">
    <!-- TABS -->
    <TabsSkeleton v-if="loadingParent && viewTabs" />
    <v-tabs class="menu-tabs my-5" :class="$vuetify.breakpoint.smAndDown ? 'mb-4' : 'mb-5'" v-else-if="viewTabs"
      v-model="tab" show-arrows>
      <v-tab v-for="tab in viewTabs.tabs" :key="tab.name" :to="tab.name" :id="tab.name">
        <v-icon class="mr-2">{{ tab.icon }}</v-icon>
        <span>
          {{ traduceItem(tab.tabData || tab.name) }}
          {{ !['infoOut', 'infoIn'].includes(tab.tabData || tab.name) ? `(${tabQuantity(tab)})` : '' }}
        </span>
      </v-tab>
    </v-tabs>
    <!-- INFO CARDS -->
    <InformationCardsSkeleton v-if="loadingParent && informationCards" />
    <InformationCards v-else-if="informationCards" :cards="informationCards" :dataComponent="dataComponent" />
    <div
      v-if="!['infoOut', 'infoIn'].includes(actualView.name) && (!showNoData || dragging || (actualView.search && ((dataColumns && dataColumns.length) || searching)) || loadingData || loadingParent)"
    >
      <CardFrame
        :title="traduceItem(`${tabData}${actualView.parent ? 'Definitions' : ''}`)"
        :actions="tableActions"
        @selectedAction="onActionSelected($event, 'tableActions')"
        :toSearch="toSearch"
        :toSearchKey="toSearchKey + maximizeTable"
        :numberFilteredResults="filteredResults.length"
        :searching="searching"
        :dataColumns="dataColumns"
        @search="debounceSearch"
      >
        <template v-slot:alerts>
          <v-alert
            v-if="actualView.infoAlert"
            class="mb-5"
            outlined
            :type="'info'"
            :icon="actualView.infoAlert.icon"
          >
            {{ $t(actualView.infoAlert.text) }}
          </v-alert>
        </template>
        <template v-slot:default>
          <DetailTableFrame
            @action-selected="onActionSelected(...$event)"
            @save-order="saveOrder"
            :dragAndDrop="dragAndDrop"
            :dragging="dragging"
            :showExpand="showExpand"
            :expanded="expanded"
            :headers="headers"
            :dataColumns="dataColumns"
            :searching="searching"
            :dataOfRequests="dataOfRequests"
            :isDialogOpen="isDialogOpen"
            :loadingParent="loadingParent"
            :configNodata="configNodata"
          />

          <v-dialog :value="maximizeTable && (!isAnyEditModalOpen)" @click:outside="changeTableSize('restoreTable')"
            @keydown.esc="changeTableSize('restoreTable')" content-class="table-dialog">
            <CardFrame
              :title="traduceItem(`${tabData}${actualView.parent ? 'Definitions' : ''}`)"
              :actions="tableActions"
              @selectedAction="onActionSelected($event, 'tableActions')"
              :toSearch="toSearch"
              :numberFilteredResults="filteredResults.length"
              :toSearchKey="toSearchKey + maximizeTable"
              :searching="searching"
              :dataColumns="dataColumns"
              @search="debounceSearch"
            >
              <DetailTableFrame
                @action-selected="onActionSelected(...$event)"
                @save-order="saveOrder"
                :dragAndDrop="dragAndDrop"
                :dragging="dragging"
                :showExpand="showExpand"
                :expanded="expanded"
                :headers="headers"
                :dataColumns="dataColumns"
                :searching="searching"
                :dataOfRequests="dataOfRequests"
                :isDialogOpen="isDialogOpen"
                :loadingParent="loadingParent"
                :configNodata="configNodata"
                :class="{'modal-wrapper': maximizeTable}"
              />
            </CardFrame>
          </v-dialog>
        </template>
      </CardFrame>
    </div>
    <template v-else>
      <v-progress-circular v-if="loadingData" indeterminate color="primary"></v-progress-circular>
      <NoData
        v-else-if="!['infoOut', 'infoIn'].includes(actualView.name)"
        :text="configNodata.text"
        :image="configNodata.image"
        :tableActions="tableActions"
        @actionSelected="onActionSelected($event, 'tableActions')" class="noData"
        :cardHeaderText="traduceItem(`${tabData}${actualView.parent ? 'Definitions' : ''}`)"
      />
    </template>
  </div>
  <div v-else>
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import i18n from "@/plugins/i18n";
import { tools } from "@/mixins/tools";
import { firebaseTools } from "@/mixins/firebase-tools";
import NoData from "../views/common/NoData.vue";
import editableDataFields from "@/mixins/editable-data-fields";
import editableDataFieldsStepper from "@/mixins/editable-data-fields-stepper";
import InformationCards from "@/components/InformationCards.vue";
import TabsSkeleton from "../skeletonComponents/TabsSkeleton.vue";
import InformationCardsSkeleton from "../skeletonComponents/InformationCardsSkeleton.vue";
import DetailTableFrame from "./DetailTableFrame.vue";
import CardFrame from './CardFrame.vue';

export default {
  name: "Detail",
  mixins: [firebaseTools, tools],
  data: () => ({
    tab: null,
    viewTabs: undefined,
    toSearchKey: 0,
    loadingComponent: false,
    filteredResults: [],
    dragging: false,
    expanded: [],
    dragAndDrop: false,
    showExpand: true,
    showNoData: false,
    headers: [],
    dataColumns: [],
    dataOfRequests: [],
    searching: false,
    isDialogOpen: {},
    isDependsOnActionHidden: false,
    maximizeTable: false,
  }),
  components: {
    NoData,
    InformationCards,
    TabsSkeleton,
    InformationCardsSkeleton,
    DetailTableFrame,
    CardFrame,
  },
  props: {
    dataComponent: { type: Object },
    headerHeight: { type: Number },
    frameType: { type: String },
    loadingParent: { type: Boolean, default: false },
  },
  methods: {
    ...mapActions([
      "setDataView",
      "setItemToAddEdit",
      "setItemToConfirmAction",
      "setItemToAddEditStepper",
      "setLoadingData",
    ]),
    debounceSearch(selectedValues) {
      if (!this.dataComponent) return;
      let fieldsSearched =
        selectedValues &&
        Object.keys(selectedValues).filter(
          (item) =>
            selectedValues[item]?.length &&
            selectedValues[item]
        );
      this.searching = fieldsSearched && fieldsSearched.length ? true : false;
      this.showExpand = this.searching ? true : this.dragAndDrop ? false : true;
      if (!this.showExpand) this.expanded = [];
      const containedData = this.deepCopyFunction(
        !this.actualView.parent?.tabs
          ? this.dataComponent.columns
          : this.dataComponent[this.tabData]
      );
      if (this.searching) {
        let data = [];
        containedData.forEach((item) => {
          let containField = true;
          fieldsSearched.forEach((field) => {
            const checkItem = (key, check) => selectedValues[field].includes(i18n.t(key)) && check;
            const optionCheck = (key) => item.columnReference?.option === key;
            if (['assignedValue'].includes(field)) {
              containField = checkItem('eachCase', item.conditionals?.length) ||
                checkItem('joinColumns', item.joinColumns?.length) ||
                checkItem('addTimeStepTransformation', item.addTimeStepTransformation) ||
                checkItem('dictionaryTransformation', item.dictionaryTransformation) ||
                checkItem('arithmeticTransformation', item.arithmeticTransformation) ||
                checkItem('substringTransformation', item.substringTransformation) ||
                ['INPUT_VALUE', 'WILDCARD', 'VARIABLE', 'INPUT_COLUMN_NAME', 'OUTPUT_COLUMN_NAME'].some(key => checkItem(key, optionCheck(key)));

            } else if (field === 'columnReference') {
              if (item.columnReference?.option === 'INPUT_COLUMN_NAME' && item?.columnReference?.value?.toLowerCase().includes(selectedValues[field].toLowerCase())) containField = true
              else containField = false
            } else if (['displayColumn'].includes(field)) {
              if (selectedValues[field].includes(i18n.t('yes')) && selectedValues[field].includes(i18n.t('no'))) containField = true
              else {
                if (selectedValues[field].includes(i18n.t('yes')) && !item.displayColumn) containField = false
                if (selectedValues[field].includes(i18n.t('no')) && item.displayColumn) containField = false
              }

            } else if (field === "nullable") {
              if (
                !selectedValues[field].includes(i18n.t("yes")) &&
                typeof item[field] === "boolean" &&
                item[field]
              )
                containField = false;
              else if (
                !selectedValues[field].includes(i18n.t("no")) &&
                typeof item[field] === "boolean" &&
                !item[field]
              )
                containField = false;
            } else {
              let valueField = item[field];
              let fieldTypeSelect = this.actualView.search.fields?.find(fieldToFind => fieldToFind.field === field && (fieldToFind.type === 'select' || fieldToFind.type === 'tagBox')) || 'name';
              if (fieldTypeSelect) valueField = this.setTranslations(item[field]);
              if (containedData[0][field] && typeof containedData[0][field] === 'object' && !Array.isArray(valueField) && valueField) {
                const deepField = this.actualView.search.fields[this.actualView.search.fields.findIndex(el => el.field === field)]['deepField'];
                if (deepField && field !== 'startDate') {
                  if (!valueField[deepField].toLowerCase().includes(selectedValues[field].toLowerCase())) containField = false

                } else if (deepField === 'seconds') {

                  const rawDate = new Date(valueField[deepField] * 1000)
                  const date = `${rawDate.getFullYear()}-${Number(rawDate.getMonth()) < 10 ? '0' + Number(rawDate.getMonth() + 1) : Number(rawDate.getMonth() + 1)}-${rawDate.getDate()}`
                  if (date !== selectedValues[field]) containField = false
                }

              } else if (typeof selectedValues[field] === "string" && typeof valueField === "string") {
                if (!valueField.toLowerCase().includes(selectedValues[field].toLowerCase())) containField = false
              } else if (Array.isArray(valueField)) {
                if (Array.isArray(selectedValues[field])) {
                  selectedValues[field].forEach(element => {
                    if (!valueField.includes(element)) containField = false;
                  })
                } else if ((!valueField.includes(selectedValues[field]))) {
                  containField = false
                }
              } else if (
                typeof selectedValues[field] === "string" &&
                typeof valueField === "string"
              ) {
                if (
                  !valueField
                    .toLowerCase()
                    .includes(selectedValues[field].toLowerCase())
                )
                  containField = false;
              } else if (
                typeof valueField === "string" &&
                Array.isArray(selectedValues[field]) &&
                !selectedValues[field].includes(valueField)
              ) {
                containField = false;
              } else if (!valueField && typeof valueField !== "number") {
                containField = false;
              } else if (typeof valueField === "number") {
                if (field === "position") {
                  if (
                    (this.actualView.search.restOneToPosition && selectedValues[field] != (valueField + 1)) ||
                    (!this.actualView.search.restOneToPosition && ((selectedValues[field] - 1) != valueField))
                  )
                    containField = false;
                } else if (selectedValues[field] != valueField)
                  containField = false;
              }
            }
          });
          if (containField) data.push(item);
        });
        this.filteredResults = data;
      } else {
        this.filteredResults = this.dataComponent[this.tabData] ?? this.dataComponent.columns;
      }
      this.setData();
    },
    modifyPosition() {
      this.dragAndDrop = !this.dragAndDrop;
      if (this.dragAndDrop) this.expanded = [];
      this.showExpand = !this.dragAndDrop;
      this.setData();
    },
    changeTableSize(action) {
      if (action === 'maximizeTable') {
        this.maximizeTable = true;
      } else if (action === 'restoreTable') {
        this.maximizeTable = false;
      }
      this.expanded = [];
      this.debounceSearch()
    },
    onActionSelected(event, actionsSource, element = null, rowIndex) {
      const documentId = this.$router.history.current.params.id;
      const actionOnActualView = this.actualView[actionsSource].find(
        (action) => action.action === event.action
      );
      const {
        updateValuesFB,
        globalValues: globalValuesForAction,
        setValuesWhenEdit,
        dynamicRequireds,
        restOneToPosition,
        requestDataSouce,
        addPositionDynamicByDefault = false
      } = actionOnActualView || {};
      const { atribute, parentAttr, operator } = updateValuesFB || {};
      const actualView = this.actualView.parent || this.actualView; // TO DO, REVISAR ESTO, ES UN LÍO
      const section = actualView.name;
      const editableFields = editableDataFields.computed[section] && this.deepCopyFunction(editableDataFields.computed[section]()[event.action]);
      const { config: configEditableDataFields, fields } = editableFields || {};
      const editableFieldsStepper = editableDataFieldsStepper.computed[section] && this.deepCopyFunction(editableDataFieldsStepper.computed[section]()[event.action]);
      let { config: stepperConfig, steps } = editableFieldsStepper || {};
      let { collectionAttr, nestedAttr, title } = stepperConfig || {};
      const translationKey = this.$route.name === 'splitConfiguration' ? event.action : this.actualView.name;
      const transFormValuesBeforeSendForm = operator === 'nestedArrayAdd' || operator === 'nestedArrayEdit';
      let globalValues = globalValuesForAction ? this.deepCopyFunction(this.dataComponent[globalValuesForAction]) : this.deepCopyFunction(this.dataComponent);
      let dataSource = this.getDataSource(this.dataComponent);
      let oldItem;
      let values = [];
      let objectItemToAddEdit = {};

      if (['editHeaderCell', 'addColumnSplit'].includes(event.action) && this.dataColumns) {
        globalValues = this.headers
      }

      if (event.action === "modifyPosition" || event.action === "keepPosition") return this.modifyPosition()
      if (['maximizeTable', 'restoreTable'].includes(event.action)) return this.changeTableSize(event.action);
      if ((event.action === "addColumn" && this.$route.name === 'columnsIn') || event.action === "importColumns") {
        this.$emit("table-action", event);
        return;
      }
      if (this.$route.name.includes('filters')) oldItem = element
      else if (collectionAttr && !nestedAttr) {
        oldItem = element
          ? this.dataComponent[collectionAttr].find(item => (item.name ?? item.position) === (element.name ?? element.position))
          : undefined;
      } else if (['nestedArrayRemove', 'nestedArrayAdd'].includes(operator)) {
        let dataToUse = (this.dataComponent[parentAttr] && this.dataComponent[parentAttr][atribute]) || [];
        oldItem = {
          [parentAttr]: {
            [atribute]: dataToUse.filter((row, index) => row && index !== rowIndex)
          }
        }
      } else if (operator === 'nestedArrayEdit') {
        oldItem = this.deepCopyFunction(element)
        if (event.action === 'deleteHeaderCell') {
          this.deepCopyFunction(globalValues[parentAttr][atribute]).forEach(({ columnReferences }, index) => {
            const itemWithOutElement = columnReferences.filter(({ alias }) => alias !== element.text).map((column, index) => {
              if (column.position !== index) column.position = index
              return column
            })
            if (!itemWithOutElement.length) {
              globalValues[parentAttr] = null
            } else {
              globalValues[parentAttr][atribute][index] = {
                columnReferences: itemWithOutElement
              }
            }
          })
          oldItem = this.deepCopyFunction(globalValues[parentAttr])
        }
      } else if (element) {
        const { columns = [] } = (this.dataComponent || {});
        oldItem = columns.find(item => item.position === element.position);
      }

      let copySelectedItem = this.deepCopyFunction(oldItem);

      if (setValuesWhenEdit) {
        Object.keys(setValuesWhenEdit).forEach((item) => {
          setValuesWhenEdit[item].forEach((field) => {
            if (copySelectedItem[field]) copySelectedItem[item] = field;
          });
        });
      }
      if (dynamicRequireds) {
        dynamicRequireds.forEach((required) => {
          if (!this.dataComponent[required.dependsOn]) {
            let findField = fields.find(
              (field) => field.name === required.field
            );
            if (findField.validators)
              findField.validators.required = { msg: i18n.t("required") };
            else
              findField.validators = { required: { msg: i18n.t("required") } };
          }
        });
      }

      if (['edit', 'editColumn', 'addColumnSplit', 'editHeaderCell'].includes(event.action)) {
        if (restOneToPosition) {
          globalValues.forEach(value => value.position = value.position + 1);
          copySelectedItem.position = copySelectedItem.position + 1;
        }
        objectItemToAddEdit = {
          section,
          fields,
          configEditableDataFields,
          currentDataValues: copySelectedItem,
          globalValues,
          dataSource,
          updateValidations: ["checkUniqueValuesValidations"],
          transFormValuesBeforeSendForm,
          originalItem: transFormValuesBeforeSendForm && { [parentAttr]: { [atribute]: (this.dataComponent[parentAttr] && this.dataComponent[parentAttr][atribute]) || [] } },
          data: {
            params: {
              collectionName: actualView.get[0].collection,
              documentId,
              updateValuesFB,
              msgAction: event.action,
            },
            method: "updateDocument",
          },
        };
      }

      switch (event.action) {
        case "showInfo":
          this.$set(this.isDialogOpen, `${element.alias}${rowIndex}`, true)
          break;
        case "addRow":
          if (this.dataColumns && this.dataColumns[0]) {
            Object.keys(this.dataColumns[0]).forEach((key) => {
              values.push({ alias: this.dataColumns[0][key]?.alias, position: this.dataColumns[0][key].position })
            })
            oldItem[parentAttr][atribute].push({ columnReferences: [...values] })
          }
          this.executeAction({
            section,
            action: 'edit',
            values: oldItem,
            data: {
              method: "updateDocument",
              params: {
                msgAction: 'addRow',
                collectionName: actualView.get[0].collection,
                documentId,
                actionSetted: event.action,
              }
            }
          });
          break;

        case "addFilter":
        case "editFilter":
        case "addColumnOut":
        case "editColumnOut":
        case "addCellValue":
        case "editCell":
          this.$set(stepperConfig, 'title', i18n.t(title, { element: `${i18n.t("row")} ${(rowIndex + 1)} - ${element?.alias}` }));
          globalValues = nestedAttr ? this.deepCopyFunction(this.dataComponent[collectionAttr][nestedAttr]) : this.deepCopyFunction(this.dataComponent[collectionAttr]);
          this.setItemToAddEditStepper({
            section,
            dataSource,
            globalValues,
            steps,
            addPositionDynamicByDefault,
            action: 'edit',
            config: stepperConfig,
            currentDataValues: element,
            indexesToUpdate: transFormValuesBeforeSendForm && { rowIndex },
            data: {
              params: {
                documentId,
                updateValuesFB,
                oldItem,
                collectionName: actualView.get[0].collection,
                msgAction: event.action,
              },
              method: "updateDocument",
            },
          })
          break;
        case "edit":
        case "editColumn":
        case "addColumnSplit":
        case "editHeaderCell":
          if (requestDataSouce) {
            const { fromdataOfRequests, atributeDataSource, requests, addHeaders } = requestDataSouce || {};
            if (fromdataOfRequests) {
              objectItemToAddEdit.data.params.oldItem = oldItem;
              dataSource[atributeDataSource] = this.dataOfRequests;
              objectItemToAddEdit = this.setCurrentValues(
                objectItemToAddEdit,
                dataSource
              );
              this.setItemToAddEdit(objectItemToAddEdit);
            } else {
              dataSource[atributeDataSource] = [];
              requests.forEach(
                (request, index) => {
                  const data = this.deepCopyFunction(this[this.collectionStore(request.collection)]);
                  let dataOfRequest = data.map((elementItem) => {
                    return { name: elementItem.name, id: elementItem.id };
                  });
                  let itemDataSource = addHeaders
                    ? [{ header: request.header }, ...dataOfRequest]
                    : dataOfRequest;
                  dataSource[atributeDataSource] = [
                    ...dataSource[atributeDataSource],
                    ...itemDataSource,
                  ];
                  if (requests.length - 1 === index) {
                    objectItemToAddEdit.data.params.oldItem = oldItem;
                    objectItemToAddEdit = this.setCurrentValues(
                      objectItemToAddEdit,
                      dataSource
                    );
                    this.setItemToAddEdit(objectItemToAddEdit);
                  }
                }
              );
            }
          } else {
            this.setItemToAddEdit(objectItemToAddEdit);
          }
          break;
        case "delete":
        case "deleteSplitConfiguration":
        case "deleteRow":
        case "deleteHeaderCell": {
          const globalDataToDeleteFrom = (parentAttr ? globalValues && globalValues[parentAttr] : globalValues);
          const isLastItemToDelete = !globalDataToDeleteFrom || (globalDataToDeleteFrom && globalDataToDeleteFrom[atribute] && globalDataToDeleteFrom[atribute].length === 1);
          this.setItemToConfirmAction({
            title: i18n.t(`delete${translationKey}Component`),
            text: i18n.t(`delete${translationKey}ComponentQuestion`),
            data: {
              isLastItemToDelete,
              params: {
                collectionName: actualView.get[0].collection,
                documentId,
                updateValuesFB,
                addPositionDynamicByDefault,
                action: "edit",
                msgAction: "deleteElementComponent",
                element: copySelectedItem,
                actionSetted: event.action !== 'delete' ? event.action : undefined,
                nameToNotify: event.action === 'deleteHeaderCell' && element ? element.text : undefined,
              },
              method: "updateDocument",
            },
          });
          break;
        }
      }
    },
    saveOrder(event) {
      const actualView = this.actualView.parent || this.actualView;
      this.expanded = [];
      this.dragging = true;

      // // Avoid deep copying dataColumns and dataComponent if unnecessary

      const copyDataColumns = [...this.dataColumns];
      const copyDataComponent = [...this.dataComponent[this.tabData]];
      const { newIndex, oldIndex } = event;
      const isHigher = oldIndex < newIndex
      const secondTerm = isHigher ? newIndex : oldIndex
      const addSub = isHigher ? -1 : 1

      for (let index = isHigher ? oldIndex + 1 : newIndex; index <= secondTerm; index++) {
        copyDataColumns[index].position = index + addSub
      }
      copyDataColumns[oldIndex].position = newIndex;

      // Sort copyDataComponent directly instead of creating a new array
      copyDataComponent.sort((col1, col2) =>
        col1.position - col2.position
      );

      // Assuming updateDocument is an asynchronous operation, you might want to await it if necessary
      this.updateDocument(
        actualView.get[0].collection,
        this.$router.history.current.params.id,
        { columns: copyDataColumns },
        "dragging",
        copyDataColumns[oldIndex].alias
      );

    },
    setData() {
      let { isDraggable, dynamicHeaders, headers } = this.actualView?.tableDetail || {}
      let headerColumns = [
        {
          text: dynamicHeaders ? i18n.t('rows') : "",
          align: "left",
          value:
            isDraggable
              ? "draggable"
              : (dynamicHeaders && 'rows') || '',
        },
      ];
      headerColumns[0].sortable = headerColumns[0].value !== 'rows';
      if (isDraggable) headerColumns[0].width = 24
      let dataColumns;
      if (this.dataView) {
        dataColumns =
          this.searching && this.filteredResults
            ? this.deepCopyFunction(this.filteredResults)
            : this.actualView.parent?.tabs
              ? this.deepCopyFunction(this.dataComponent ? this.dataComponent[this.tabData] : [])
              : this.deepCopyFunction((this.dataComponent || {}));

        if (dynamicHeaders) {
          if (dataColumns) {
            let rows = this.deepCopyFunction(dataColumns?.linesReferences)
            let rowsTransformed = rows && this.deepCopyFunction(rows).map(({ columnReferences }) => columnReferences)
            dataColumns = this.deepCopyFunction(rowsTransformed)
            dataColumns = dataColumns && this.deepCopyFunction(dataColumns).map(row => {
              return row.reduce((obj, item) => {
                return {
                  ...obj,
                  [item.alias]: { ...item }
                }
              }, {})
            })
          } else {
            dataColumns = []
          }
        }
      } else return;
      if (dataColumns && dataColumns.length) {
        this.filteredResults = dataColumns;
        if (dynamicHeaders) {
          headers = Object.keys(dataColumns[0])
        }
        headers.forEach((element, index) => {
          const elementText = dynamicHeaders ? element : i18n.t(element);
          if (index === 0 && !isDraggable && !dynamicHeaders) {
            headerColumns[0].text = elementText,
              headerColumns[0].value = element;
          } else {
            headerColumns.push({
              text: elementText,
              value: element,
              sortable: !dynamicHeaders && element !== 'conditions',
            });
          }
        });
        dataColumns.forEach((element) => {
          Object.keys(element).forEach((key) => {
            if (
              headers.includes(key) &&
              !element[key] &&
              element[key] !== 0 &&
              typeof element[key] !== "boolean"
            )
              element[key] = '';
            else if (!["decimalSeparator", "alias"].includes(key))
              element[key] = this.setTranslations(element[key]);
          });
        });
      }
      headerColumns.push({ value: "actions", sortable: false, width: "48" });
      if (this.showExpand && !this.actualView.disableExpand)
        headerColumns.push({ text: "", value: "data-table-expand" });
      else if (!this.actualView.disableExpand)
        headerColumns.push({
          sortable: false,
          value: "disabledExpand",
          width: "48",
        });
      else this.showExpand = false;
      this.headers = headerColumns;
      this.showNoData = this.headers.filter(item => item.value !== "" && item.value !== "actions" && item.value !== "data-table-expand" && item.value !== "draggable" && item.value !== "disabledExpand" && item.value !== 'rows').length ? false : true;
      if (dataColumns && dataColumns.length) {
        dataColumns = dataColumns.sort((col1, col2) =>
          col1.position > col2.position
            ? 1
            : col1.position < col2.position
              ? -1
              : 0
        );
      }
      this.dataColumns = dataColumns;
      this.dragging = false;
      if (!this.dataColumns || (this.dataColumns && !this.dataColumns.length)) this.dragAndDrop = false;
    },
    setRequest() {
      if (!this.dragging) this.loadingComponent = true;
      if (
        this.actualView.mountedRequests &&
        this.actualView.mountedRequests.requests &&
        this.actualView.mountedRequests.requests.length &&
        this.listsData &&
        this.dictionariesData
      ) {
        this.actualView.mountedRequests.requests.forEach((request, index) => {
          const data = this.deepCopyFunction(this[this.collectionStore(request.collection)]);
          let dataOfRequest = data.map((element) => ({
            name: element.name,
            id: element.id,
          }));
          let itemDataSource = this.actualView.mountedRequests.addHeaders
            ? [{ header: request.header }, ...dataOfRequest]
            : dataOfRequest;
          this.dataOfRequests = [...this.dataOfRequests, ...itemDataSource];
          if (this.actualView.mountedRequests.requests.length - 1 === index) {
            this.setData();
            this.loadingComponent = false;
          }
        });
      } else {
        this.setData();
        this.loadingComponent = false;
      }
    },
    setTranslations(item) {
      return typeof item === 'string' && i18n.te(item) ? i18n.t(item) : item;
    },
    forceToSearchUpdate() {
      this.toSearchKey += 1;
    },
    iterateTabs(tabs) {
      for (const tab of tabs) {
        if (tab.name === this.$route.name) return true;
      }
    },
    getTabs(view) {
      if (view.parent && view.parent.tabs) this.viewTabs = view.parent;
      else this.viewTabs = this.actualView.tabs;
    },
    tabQuantity({ tabData, name, childTabData }) {
      if (!this.dataComponent) return 0;
      const dataForView = this.dataComponent[tabData || name]
      const nestedDataForView = childTabData && dataForView && dataForView[childTabData]
      return nestedDataForView?.length || dataForView?.length || 0;
    },
    setAssignedValue(item) {
      let value;
      if (item.conditionals && item.conditionals !== '--') value = 'eachCase'
      else value = ['addTimeStepTransformation', 'dictionaryTransformation', 'columnReference', 'joinColumns', "arithmeticTransformation", 'substringTransformation']
        .map(el => item[el]
          ? el !== 'columnReference'
            ? el === 'joinColumns'
              ? item[el].length && el
              : el
            : item[el]['option']
          : null).filter(Boolean)[0]
      return value
    },
  },
  mounted() {
    this.getTabs(this.actualView);
    this.headers = [];
    this.dataColumns = [];
    this.setRequest()
  },
  watch: {
    dataColumns(newItem, oldItem) {
      this.dragAndDrop = this.dragAndDrop && this.dataColumns.length > 1 ? true : false;
      if (
        this.loadingData &&
        this.dataColumns?.length &&
        (
          newItem?.length === oldItem?.length ||
          !oldItem && newItem?.length ||
          !oldItem?.length && newItem?.length
        )
      ) this.setLoadingData(false)
    },
    actualView(newView, oldView) {
      if (newView.parent.name !== oldView.parent.name) this.tab = 0;
      this.getTabs(this.actualView);
    },
    dataComponent() {
      this.headers = [];
      this.dataColumns = [];
      this.dataOfRequests = [];
      this.setRequest();
      if (this.searching) {
        this.searching = false;
        this.debounceSearch()
      }
    },
    listsData() {
      this.dataOfRequests = [];
      this.setRequest();
    },
    dictionariesData() {
      this.dataOfRequests = [];
      this.setRequest();
    },
  },
  computed: {
    ...mapState([
      "dataView",
      "actualView",
      "envTheme",
      "dictionariesData",
      "listsData",
      "loadingData",
      "isAnyEditModalOpen",
    ]),
    modifyPositionAction() {
      let modify = (!this.dragAndDrop || this.searching)
      return {
        action: modify ? "modifyPosition" : "keepPosition",
        icon: modify ? "mdi-priority-low" : "mdi-cancel",
        label: modify ? "modifyPosition" : "keepPosition",
        textColor: this.envTheme["--gray"],
        disabled: this.searching,
        showWhen: {
          operator: '>',
          value: 1
        }
      }
    },
    maximizeTableAction() {
      if (!this.dataColumns || !this.dataColumns?.length) return [];
      const actionLabel = this.maximizeTable ? "restoreTable" : "maximizeTable";
      return [
        {
          action: actionLabel,
          icon: this.maximizeTable ? "mdi-arrow-collapse" : "mdi-arrow-expand",
          label: actionLabel,
          addPositionDynamicByDefault: true
        }
      ]
    },
    configNodata() {
      return {
        text: i18n.t(
          !this.searching ? `noData.${this.actualView.name}${this.isDependsOnActionHidden ? 'NoActions' : ''}` : "noSearchData"
        ),
        image: !this.searching ? "noData" : "search",
      };
    },
    tableActions() {
      let actions = this.actualView.tableActions
      if (this.actualView.tableDetail?.type === 'draggable') actions = [...actions, this.modifyPositionAction]
      if (['columnsIn', 'columnsOut'].includes(this.actualView.name)) actions = [...actions, ...this.maximizeTableAction]
      return this.setActions(actions)?.filter(a => {
        const showWhen = Object.prototype.hasOwnProperty.call(a, 'showWhen') && a.showWhen;
        if (a.dependsOnData && !(this.dataComponent && this.dataComponent[a.dependsOnData] && this.dataComponent[a.dependsOnData].length)) {
          this.isDependsOnActionHidden = true;
          return null;
        }
        return (
          a.showWhen === undefined ||
          (showWhen === 'empty' && (!this.dataColumns || !this.dataColumns?.length)) ||
          eval(this.dataColumns?.length + showWhen.operator + showWhen.value)
        )

      });
    },
    toSearch() {
      this.forceToSearchUpdate();
      const isAdvanced = this.actualView.search && typeof this.actualView.search !== "boolean";
      
      let fields = [
        {
          field: "name",
          label: i18n.t("name"),
          type: "text",
        },
      ];

      if (isAdvanced) {
        const containedData = this.dataComponent?.[this.tabData];
        const { fields: fieldsConfig } = this.actualView.search || {};
        fields = fieldsConfig.map(({ field, label, type, deepField, setData }) => {
          let data = []
          if (setData) {
            data = setData.map((elementData) => (i18n.te(elementData) ? i18n.t(elementData) : elementData));
          } else {
            const newData = containedData?.map((el) => {
              let valueToReturn;
              if (field === 'columnReference') {
                if (el?.columnReference?.option === 'INPUT_COLUMN_NAME' && el?.columnReference?.value) valueToReturn = el.columnReference.value
                else return;
              } else if (deepField) {
                valueToReturn = el[field][deepField] && this.setTranslations(el[field][deepField])
              } else if (field === 'assignedValue') {
                valueToReturn = this.setAssignedValue(el)
              } else if (field === 'position') {
                valueToReturn = el[field] + 1
              } else {
                valueToReturn = el[field]
              }

              return valueToReturn
            })
            .filter(Boolean)
            .flatMap((el) => (
              type === "tagBox"
                ? (i18n.te(el) && i18n.t(el) || el)
                : el
              ).toString()
            )
            data = [
              ...new Set(newData),
            ];
          }

          if (!data) data = []
          return {
            field,
            label: i18n.t(label || field),
            type,
            data: type !== "date" && data,
          };
        }).filter(Boolean);
      }

      return ({
        label: i18n.t(`${this.actualView.name}`),
        resultsLabel: i18n.t("resultsFound"),
        selectAllLabel: i18n.t("selectAll"),
        hasAdvancedSearch: isAdvanced,
        fields,
      })
    },
    heigthColumn() {
      return this.headerHeight + 30;
    },
    informationCards() {
      return this.actualView.parent?.informationCards || this.actualView.informationCards;
    },
    tabData() {
      return this.actualView.tabData ?? this.actualView.name
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  padding: 0px;
}

.expandible-key {
  color: var(--fontColorTerciary);
}

.expandible-section {
  color: var(--primary);
  font-size: 16px;
}

.noData {
  font-size: 18px;
  padding: 0;
}

</style>
