<template>
  <div>
    <v-form ref="loginForm" @submit.prevent="doLogin">
      <v-card-text class="pa-0 mb-2">
        <v-text-field
          v-model="user"
          :placeholder="traduceItem('user')"
          name="login"
          id="login"
          :rules="[rules.required]"
        ></v-text-field>

        <v-text-field
          v-model="password"
          :placeholder="traduceItem('password')"
          type="password"
          id="password"
          name="password"
          :rules="[rules.required]"
          autocomplete="off"
        ></v-text-field>

        <div class="v-messages error--text" v-if="errorMessage">
          {{ traduceItem(errorMessage) }}
        </div>

        <div class="app-version mt-2 text-center">
          v{{ $appConfig.CONFIG.VERSION }}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          depressed
          rounded
          color="var(--primary)"
          type="submit"
          :loading="loading"
          :disabled="loading"
          class="mt-3"
          :style="{ color: 'var(--white)' }"
        >
          <v-icon left>mdi-import</v-icon>
          <span>{{ traduceItem("login") }}</span>
        </v-btn>
      </v-card-actions>
    </v-form>
    <v-card-actions>
      <v-btn
        depressed
        rounded
        color="var(--primary)"
        type="submit"
        @click="loginWithGoogle"
        class="mt-3"
        :style="{
          color: '#555555',
          backgroundColor: 'var(--bgSecondary)',
          border: '1px solid var(--borderGray)',
        }"
      >
        <div
          class="logo"
          :style="{
            'background-image': envTheme['--iconGoogle'],
          }"
        ></div>
        <span class="label-login-google">{{ traduceItem("loginWithGoogle") }}</span>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { authentication } from "@/mixins/authentication.js";
import { tools } from "@/mixins/tools";
import i18n from "@/plugins/i18n";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  // connectAuthEmulator,
} from "firebase/auth";
import { mapActions, mapState } from "vuex";
import { firebaseTools } from "@/mixins/firebase-tools";

const auth = getAuth();
if (location.hostname === "localhost") {
  // Point to the Storage emulator running on localhost.
  // connectAuthEmulator(auth, "http://localhost:9099");
}

export default {
  name: "Login",
  data: () => ({
    loading: false,
    user: null,
    password: null,
    lang: null,
    rules: {
      required: (value) => !!value || i18n.t("required"),
    },
    errorMessage: null,
  }),
  computed: {
    ...mapState(["envTheme"]),
  },
  methods: {
    ...mapActions(["setShowSnackBar"]),
    doLogin() {
      this.loading = true;
      this.errorMessage = null;

      if (!this.$refs.loginForm.validate()) {
        this.loading = false;
        return;
      }

      signInWithEmailAndPassword(auth, this.user, this.password)
        .then((userCredential) => {
          const { accessToken = null } = userCredential.user || {};
          this.createUserSession({
            userLogged: {
              ...userCredential.user,
            },
            jwt: accessToken,
          });
          this.checkSession();
          this.loading = false;
        })
        .catch((error) => {
          this.errorMessage = this.handleFireBaseRequests(error.code);
          this.loading = false;
        });
    },
    loginWithGoogle() {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then(async(result) => {
          const { accessToken = null } = result.user || {};
          const userSync = await this.syncUserPhotoWithGoogle({...result.user});
          this.createUserSession({
            userLogged: {
              ...userSync,
            },
            jwt: accessToken,
          });
          this.checkSession();
        })
        .catch((error) => {
          this.handleFireBaseRequests(error.code);
        });
    },
    async syncUserPhotoWithGoogle(user) {
      const googlePhoto = (user.providerData ?? []).find(
        (item) => item.providerId === "google.com"
      )?.photoURL;
      if (googlePhoto && googlePhoto !== user.photoURL) {
        const isUpdateImage = await this.updateUserPhoto(googlePhoto);
        if(isUpdateImage){
          user.photoURL = googlePhoto
        }
      }
      return user
    },
  },
  mixins: [authentication, tools, firebaseTools],
  created() {
    this.lang = navigator.language || navigator.userLanguage;

    if (this.lang.indexOf("-") > -1) {
      this.lang = this.lang.substring(0, this.lang.indexOf("-"));
    }
  },
};
</script>
<style lang="scss">
.logo {
  width: 18px;
  height: 18px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 7px;
}
.label-login-google {
  font-weight: 400;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: var(--bgSecondary);
  box-shadow: inherit !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
</style>
