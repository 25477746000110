<template>
  <div
    class="import-file"
    id="app-container"
    @keydown.enter="handleKeydown"
    @keydown.shift.tab="handleShiftTab"
  >
    <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
    <template v-else>
      <ImportSourceSelector
        :hasTrafisCloudBtn="hasTrafisCloudBtn"
        :wrpClasses="wrpClasses"
        :color="'primary'"
        :selectedSource="selectedImportSource"
        :googleDeveloperKey="googleDeveloperKey"
        :googleClientId="googleClientId"
        :googleAppId="googleAppId"
        @sourceSelected="changedSelectedImportSource"
        @fileSelectedDrive="fileSelectedDrive"
        ref="importSourceSelector"
      />
      <template v-if="alert">
        <v-alert
          outlined
          :type="alert.type"
          :icon="alert.icon"
          :color="alert.color"
          class="mt-5"
        >
          {{ $t(alert.msg) }}
        </v-alert>
      </template>

      <Import
        v-if="selectedImportSource === 'yourPc'"
        class="importFileArea mt-5"
        :title="$t('importFileFromYourPc')"
        :subtitle="$t('clickOrDragToImport')"
        :icon="'mdi-laptop'"
        @file="fileSelected"
        :color="'primary'"
        id="importComponent"
        tabindex="0"
        ref="importFileContainer"
      />

      <FileToUpload
        v-if="selectedFile && selectedImportSource !== 'cloud'"
        :file="selectedFile"
        :wrpClasses="wrpClasses"
        @clearFile="clearFile"
        @focusImportFile="focusImportFile"
        class="mt-5"
      />
      
      <CloudFileToUpload  
        v-if="selectedImportSource === 'cloud'"
        :resetForm="resetComponent" 
        :cloudFiles="cloudFiles"
        :currentDataValues="currentDataValues"
        @cloudFile="fileSelected"
        :submitFormComponent="submitFormComponent"  
      />
    </template>
  </div>
</template>

<script>
import ImportSourceSelector from "@/components/ImportSourceSelector.vue";
import FileToUpload from "@/components/FileToUpload.vue";
import CloudFileToUpload from "@/components/CloudFileToUpload.vue";
import i18n from "@/plugins/i18n";
import { mapState } from "vuex";
import { firebaseTools } from "@/mixins/firebase-tools";

export default {
  name: "ImportFile",
  components: {
    ImportSourceSelector,
    FileToUpload,
    CloudFileToUpload
  },
  mixins: [firebaseTools],
  props: {
    submitFormComponent: { type: Boolean },
    currentDataValues: { type: Object },
    resetComponent: { type: Boolean },
    hasTrafisCloudBtn: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => ({ allowedExtensionFiles: ["csv", "xlsx"] }),
    },
  },
  data: () => ({
    googleAppId: window.PROJECT_ENV.GOOGLE_APP_ID,
    googleClientId: window.PROJECT_ENV.GOOGLE_CLIENT_ID,
    googleDeveloperKey: window.PROJECT_ENV.API_KEY,
    selectedImportSource: undefined,
    selectedFile: undefined,
    errorFileImport: undefined,
    loading: false,
    importFileAreaFocused: false,
    cloudFiles: [],
    warningFileImport: undefined
  }),
  async created() {
    if (this.hasTrafisCloudBtn) await this.setCloudFiles();
    const { selectedFile, selectedFrom } = this.currentDataValues || {};
    this.selectedFile = selectedFile;
    this.selectedImportSource = selectedFrom || 'yourPc';
  },
  computed: {
    ...mapState(["actualGroup"]),
    wrpClasses() {
      return {
        medium: true,
      };
    },
    allowedExtensionFilesRegexp() {
      const { allowedExtensionFiles } = this.config;
      return new RegExp(`[^s]+(.*?).(${allowedExtensionFiles.join("|")})$`);
    },
    notShowErrorMsg() {
      return this.cloudFiles?.length && this.selectedImportSource === 'cloud' && this.errorFileImport === 'youMustSelectAtLeastOneFileToImport';
    },
    alert() {
      let alert = {
        type: this.warningFileImport ? 'warning' : 'error',
        icon: this.warningFileImport ? 'mdi-alert' : 'mdi-alert-circle',
        color: this.warningFileImport ? 'warning' : 'error',
        msg: this.warningFileImport || this.errorFileImport
      };
      return this.notShowErrorMsg || !alert.msg ? undefined : alert;
    }
  },
  watch: {
    selectedImportSource(newVal) {
      if (newVal === "yourPc") {
        this.$nextTick(() => {
          const importComponent = document.getElementById("importComponent");
          if (importComponent) {
            importComponent.focus();
            this.importFileAreaFocused = true;
          }
        });
      }
    },
    submitFormComponent(val) {
      if (val && !this.errorFileImport) {
        if (!this.selectedImportSource) {
          this.errorFileImport = "youMustSelectAnImportOption";
        } else if (!this.selectedFile) {
          this.errorFileImport = "youMustSelectAtLeastOneFileToImport";
        } else {
          this.errorFileImport = undefined;
        }
      }
    },
    errorFileImport(val) {
      if (val) {
        this.$emit("isValid", false);
      } else {
        this.$emit("isValid", true);
      }
    },
    selectedFile(val) {
      if (this.hasTrafisCloudBtn && this.selectedImportSource !== 'cloud') this.checkIfFileIsInStorageAndSetValidation();
      this.$emit("isValid", Boolean(val && !this.errorFileImport));
      this.$emit(
        "update",
        val
          ? { selectedFile: val, selectedFrom: this.selectedImportSource }
          : null
      );
    },
    resetComponent(val) {
      if (val) {
        this.selectedImportSource = undefined;
        this.selectedFile = undefined;
        this.errorFileImport = undefined;
        this.warningFileImport = undefined;
        this.clearInnerInput();
      }
    },
  },

  methods: {
    checkIfFileIsInStorageAndSetValidation() {
      const cloudFilesNames = this.cloudFiles.map(file => file.name);
      const {name} = this.selectedFile || {};
      if (cloudFilesNames.includes(name)) this.warningFileImport = 'warningCloudFile';
      else this.warningFileImport = undefined;
    },
    async setCloudFiles() {
      const path = `${this.actualGroup.id}/input`;
      this.loading = true;
      await this.getFilesFromStorage(path).then(files => {
        this.cloudFiles = files;
        this.loading = false;
      });
    },
    changedSelectedImportSource(selectedSource) {
      this.$emit("dirty", true);
      this.selectedImportSource = selectedSource;
      this.selectedFile = undefined;
      this.errorFileImport = undefined;
      this.warningFileImport = undefined;
    },
    isValidExtensionFile(name) {
      const valid = this.allowedExtensionFilesRegexp.test(name);
      if (!valid) this.clearInnerInput();
      this.errorFileImport = !valid
        ? i18n.t("extensionErrorFile", {
            element: this.config?.allowedExtensionFiles.join(
              ` ${i18n.t("or")} .`
            ),
          })
        : undefined;
      return valid;
    },
    fileSelected(event) {
      const { name } = event || {};
      this.errorFileImport = undefined;
      this.selectedFile =
        event && this.isValidExtensionFile(name) ? event : undefined;
    },
    async fileSelectedDrive({ fileData, oauthToken }) {
      this.loading = true;
      const fetchOptions = {
        headers: { Authorization: `Bearer ${oauthToken}` },
      };
      const { id, name, mimeType, lastEditedUtc, size } = fileData;
      const blob = await fetch(
        `https://www.googleapis.com/drive/v3/files/${id}?alt=media`,
        fetchOptions
      ).then((res) => {
        this.loading = false;
        return res.blob();
      });
      const fileOptions = {
        type: mimeType,
        lastModified: lastEditedUtc,
        size,
      };

      this.selectedFile =
        blob && this.isValidExtensionFile(name)
          ? new File([blob], name, fileOptions)
          : undefined;
    },
    clearFile() {
      if (this.selectedImportSource === "drive")
        this.selectedImportSource = undefined;
      this.selectedFile = undefined;
      this.clearInnerInput();
    },
    clearInnerInput() {
      const inputFile = document.querySelector(
        ".import-file input[type='file']"
      );
      if (inputFile) inputFile.value = "";
    },
    focusImportFile() {
      this.clearFile();
      this.$nextTick(() => {
        const importComponent = document.getElementById("importComponent");
        if (importComponent) {
          importComponent.focus();
        }
      });
    },
    handleKeydown(event) {
      if (
        event.key === "Enter" &&
        event.target.classList.contains("importFileArea")
      ) {
        const inputFile = document.getElementById("file");
        if (inputFile) {
          inputFile.click();
        }
      }
    },
    handleShiftTab(event) {
      if (
        event.shiftKey &&
        event.key === "Tab" &&
        event.target.classList.contains("importFileArea")
      ) {
        event.preventDefault();
        this.$nextTick(() => {
          const importSourceSelector = this.$refs.importSourceSelector;
          if (importSourceSelector) {
            this.$nextTick(() => {
              const pcButton = importSourceSelector.$refs.pcButton.$el;
              if (pcButton) {
                importSourceSelector.focusPCButton();
                this.clearFile();
                pcButton.click();
              } else {
                console.error(
                  "No se pudo encontrar pcButton o no es un HTMLElement válido en importSourceSelector.$refs."
                );
              }
            });
          } else {
            console.error(
              "No se pudo encontrar importSourceSelector en $refs."
            );
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-alert {
  font-size: 16px;
}

.importFileArea {
  color: var(--fontColorTerciary);
  min-height: 200px;
  width: 95%;

  ::v-deep {
    .dropArea {
      p {
        margin: 0;
      }

      padding: 30px 85px;
    }

    .dropArea i {
      height: 100%;
      color: var(--primary);
    }

    .dragFile {
      font-weight: 400;
    }

    .clickImport {
      font-weight: 500;
    }

    &.importFileArea:focus-visible {
      outline: 2px solid #9c40c7;
      outline-offset: 4px;
      border-radius: 10px;
    }
  }
}
</style>
