<template>
    <v-autocomplete
    v-if="cloudFiles.length"
    class="mt-8"
    ref="cloudFileInput"
    return-object
    item-text="name"
    v-model="selectedCloudFile"
    :label="fieldSelectCloudFile.label"
    :items="cloudFiles"
    :chips="false"
    clearable
    @input="update"
    :rules="[...fieldSelectCloudFile.validators]"
    :no-data-text="$t('noDataSelects.cloud')"
  >
    <template v-slot:item="{ attrs, item }">
          <v-list-item-content>
            <v-list-item-title>
              <v-tooltip
                bottom
                :disabled="!isTooltipActivated(`${attrs.id}ListItem`)"
              >
                <template v-slot:activator="{ on }">
                  <div
                    class="text-truncate"
                    v-on="on"
                    @mouseover="setTooltipActivated($event, `${attrs.id}ListItem`)"
                  >
                    {{ item.name }}
                  </div>
                </template>
                <span>{{ item.name }}</span>
              </v-tooltip>
            </v-list-item-title>
          </v-list-item-content>
        </template>
    </v-autocomplete>
    <div v-else class="noData mt-12">
      {{$t('noDataCloudFiles')}}
    </div>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
  name: "CloudFileToUpload",
  props: {
    resetForm: { type: Boolean },
    submitFormComponent: { type: Boolean },
    currentDataValues: { type: Object },
    cloudFiles: {type: Array}
  },
  data: () => ({
    selectedCloudFile: undefined,
    tooltipActivated: {},
  }),
  methods: {
    update() {
      this.$emit("cloudFile", this.selectedCloudFile);
    },
    isTooltipActivated(ref) {
			return this.tooltipActivated[ref];
		},
    setTooltipActivated(event, ref) {
			const { target } = event;
			this.$set(
				this.tooltipActivated,
				ref,
				target.offsetWidth < target.scrollWidth,
			);
		},
  },
  created() {
    const { selectedFile } = this.currentDataValues || {};
    if (selectedFile) this.selectedCloudFile = selectedFile;
  },
  watch: {
    resetForm() {
      this.$refs.cloudFileInput.reset();
    },
    submitFormComponent(val) {
      if (val && this.$refs && this.$refs.cloudFileInput) {
        this.$refs.cloudFileInput.hasFocused = true;
        this.$refs.cloudFileInput.validate();
      }
    },
  },
  computed: {
    fieldSelectCloudFile() {
      return {
        name: "cloudFile",
        label: i18n.t("labelCloudSelectFile"),
        validators: [(v) => (v && true) || i18n.t("required")],
      };
    },
  },
};
</script>

<style scoped lang="scss">
.noData {
  color: var(--lightGray);
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  justify-content: center;
}
.v-list {
	max-width: 414px;
}
</style>
