<template>
  <v-btn v-bind="buttonProps" v-on="$listeners" icon>
    <v-avatar v-if="!hasError && photoURL" :size="size">
      <img :src="photoURL" :alt="`${name || 'User'}'s avatar`" :width="size" :height="size" @error="handleImageError" />
    </v-avatar>
    <v-icon v-else :size="size" :color="iconColor">mdi-account-circle</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ProfileAvatar',
  props: {
    photoURL: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    size: {
      type: [Number, String],
      default: 40
    },
    iconColor: {
      type: String,
      default: 'var(--fontColorSecondary)'
    },
    buttonProps: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      hasError: false
    }
  },
  watch: {
    photoURL() {
      this.hasError = false;
    }
  },
  methods: {
    handleImageError() {
      this.hasError = true;
    }
  }
}
</script>

<style scoped>
img {
  object-fit: cover;
}
</style>