import i18n from "@/plugins/i18n";

export default {
  computed: {
    columnsIn() {
      return {
        title: i18n.t("importColumns"),
        importUrl: "import/columns",
        attrToAppendData: "inputColumns",
        steps: [
          {
            title: i18n.t("selectImportFile"),
            resume: {
              from: "currentDataValues",
              fields: [
                {
                  label: i18n.t("fileType"),
                  value: "fileType",
                  lowerCase: true,
                },
                {
                  label: i18n.t("sheetName"),
                  value: "sheetName",
                  showOnlyIfHasValue: true,
                },
              ],
            },
            fields: [
              {
                name: "fileType",
                hideTitle: true,
                resetVars: true,
                fieldType: "radioGroup",
                dataSource: [
                  { value: "CSV", name: "csv" },
                  { value: "XLSX", name: "xlsx" },
                ],
                optionsToCheck: [
                  { field: "fileType", value: "CSV" },
                  { field: "fileType", value: "XLSX" },
                ],
                validators: {
                  required: { msg: i18n.t("required") },
                },
              },
              {
                name: "sheetName",
                label: i18n.t("sheetName"),
                fieldType: "textBox",
                validators: {
                  dependsOn: [
                    {
                      field: "fileType",
                      type: "radioGroup",
                      value: "XLSX",
                    },
                  ],
                },
              },
            ],
          },
          {
            title: i18n.t("columnsToRead"),
            resume: {
              from: "currentDataValues",
              fields: [
                {
                  label: i18n.t("inputAlias"),
                  value: "alias",
                },
                {
                  label: i18n.t("positionColumn"),
                  value: "position",
                },
                {
                  label: i18n.t("mandatoryColumn"),
                  value: "nullable",
                },
                {
                  label: i18n.t("dataTypeColumn"),
                  value: "dataType",
                },
              ],
            },
            fields: [
              {
                name: "alias",
                label: i18n.t("inputAlias"),
                fieldType: "textBox",
                validators: {
                  required: { msg: i18n.t("required") },
                },
              },
              {
                name: "position",
                label: i18n.t("positionColumn"),
                fieldType: "textBox",
                validators: {
                  required: { msg: i18n.t("required") },
                },
              },
              {
                name: "nullable",
                label: i18n.t("mandatoryColumn"),
                fieldType: "textBox",
              },
              {
                name: "dataType",
                label: i18n.t("dataTypeColumn"),
                fieldType: "textBox",
                validators: {
                  required: { msg: i18n.t("required") },
                },
              },
            ],
          },
          {
            title: i18n.t("rowsToRead"),
            chipResume: {
              label: i18n.t("filter"),
              from: "currentDataValues",
              chipFields: ["filterColumnName", "filterValue"],
              fallBack: i18n.t("withoutFilters"),
              dependsOn: {
                key: "filter",
                value: true,
              },
            },
            fields: [
              {
                name: "filter",
                preventUpdate: true,
                resetVars: true,
                hideTitle: true,
                fieldType: "radioGroup",
                dataSource: [
                  { value: "all", name: i18n.t("all") },
                  { value: true, name: i18n.t("filterByColumn") },
                ],
                optionsToCheck: [
                  { field: "filter", value: "all" },
                  { field: "filter", value: true },
                ],
                validators: {
                  required: { msg: i18n.t("required") },
                },
              },
              {
                name: "firstTermSection",
                label: i18n.t("filterColumnNameSection"),
                fieldType: "sectionTitle",
                validators: {
                  dependsOn: [
                    {
                      field: "filter",
                      type: "radioGroup",
                      value: true,
                    },
                  ],
                },
              },
              {
                name: "filterColumnName",
                label: i18n.t("filterColumnName"),
                fieldType: "textBox",
                validators: {
                  dependsOn: [
                    {
                      field: "filter",
                      type: "radioGroup",
                      value: true,
                    },
                  ],
                  required: { msg: i18n.t("required") },
                },
              },
              {
                name: "filterValueSection",
                label: i18n.t("filterColumnValue"),
                fieldType: "sectionTitle",
                validators: {
                  dependsOn: [
                    {
                      field: "filter",
                      type: "radioGroup",
                      value: true,
                    },
                  ],
                },
              },
              {
                name: "filterValue",
                label: i18n.t("value"),
                fieldType: "textBox",
                validators: {
                  dependsOn: [
                    {
                      field: "filter",
                      type: "radioGroup",
                      value: true,
                    },
                  ],
                  required: { msg: i18n.t("required") },
                },
              },
            ],
          },
          {
            title: i18n.t("selectFileToImport"),
          },
        ],
      }
    },
    columnsOut() {
      return {
        title: i18n.t("importColumns"),
        importUrl: "import/outputColumns",
        attrToAppendData: "outputColumns",
        steps: [
          {
            title: i18n.t("selectImportFile"),
            resume: {
              from: "currentDataValues",
              fields: [
                {
                  label: i18n.t("fileType"),
                  value: "fileType",
                  lowerCase: true,
                },
                {
                  label: i18n.t("sheetName"),
                  value: "sheetName",
                  showOnlyIfHasValue: true,
                },
              ],
            },
            fields: [
              {
                name: "fileType",
                hideTitle: true,
                resetVars: true,
                fieldType: "radioGroup",
                dataSource: [
                  { value: "CSV", name: "csv" },
                  { value: "XLSX", name: "xlsx" },
                ],
                optionsToCheck: [
                  { field: "CSV", value: "CSV" },
                  { field: "XLSX", value: "XLSX" },
                ],
                validators: {
                  required: { msg: i18n.t("required") },
                },
              },
              {
                name: "sheetName",
                label: i18n.t("sheetName"),
                fieldType: "textBox",
                validators: {
                  dependsOn: [
                    {
                      field: "fileType",
                      type: "radioGroup",
                      value: "XLSX",
                    },
                  ],
                },
              },
            ],
          },
          {
            title: i18n.t("columnsToRead"),
            resume: {
              from: "currentDataValues",
              fields: [
                {
                  label: i18n.t("positionColumn"),
                  value: "position",
                },
                {
                  label: i18n.t("inputAlias"),
                  value: "referencedInputColumn",
                },
                {
                  label: i18n.t("outputColumnName"),
                  value: "alias",
                },
                {
                  label: i18n.t("showFinalReportColumn"),
                  value: "showInFile",
                },
              ],
            },
            fields: [
              {
                name: "position",
                label: i18n.t("positionColumn"),
                fieldType: "textBox",
                validators: {
                  required: { msg: i18n.t("required") },
                },
              },
              {
                name: "referencedInputColumn",
                label: i18n.t("inputAlias"),
                fieldType: "textBox",
                validators: {
                  required: { msg: i18n.t("required") },
                },
              },
              {
                name: "alias",
                label: i18n.t("outputColumnName"),
                fieldType: "textBox",
                validators: {
                  required: { msg: i18n.t("required") },
                },
              },
              {
                name: "showInFile",
                label: i18n.t("showFinalReportColumn"),
                fieldType: "textBox",
                validators: {
                  required: { msg: i18n.t("required") },
                },
              },
            ],
          },
          {
            title: i18n.t("selectFileToImport"),
          },
        ],
      }
    }
  },
};
