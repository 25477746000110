<template> 
<div>
  <DataTableSkeleton
    v-if="dragging || loadingData || loadingParent"
    :columnsNumber="numColumnsSkeleton"
  />
  <v-data-table
    v-else-if="dataColumns && dataColumns.length && !loadingData && !loadingParent"
    :key="dragAndDrop && !searching"
    :class="{ 'dynamic-headers': actualView.tableDetail.dynamicHeaders, 'row-not-expansible': !showExpand && !dragAndDrop }"
    class="table-detail fixed-header-table"
    fixed-header
    hide-default-footer
    :style="dragAndDrop && !searching ? { cursor: 'move' } : {}"
    :custom-sort="customSort"
    :expanded.sync="expandedItems"
    :headers="headers"
    :items="dataColumns"
    item-key="position"
    :items-per-page="dataColumns.length"
    :show-expand="showExpand"
    @click:row="onRowClick"
    @sorted="onSorted"
    v-sortable-data-table="actualView.tableDetail.isDraggable && dragAndDrop && !searching ? true : false"
  >
    <template v-slot:item.disabledExpand>
      <v-tooltip bottom :max-width="200">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" :color="envTheme['--ligthGray']">mdi-chevron-down</v-icon>
        </template>
        <span>{{ $t("disabledExpandTooltip") }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.draggable>
      <v-tooltip
        :key="dragAndDrop && !searching"
        bottom
        :max-width="200"
        :disabled="dragAndDrop && !searching"
      >
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" :color="dragAndDrop && !searching
              ? envTheme['--colorGrayText']
              : 'var(--borderGray)'
            ">mdi-arrow-all</v-icon>
        </template>
        <span>{{
          searching
            ? $t("disabledDragSearchingTooltip")
            : $t("disabledDragTooltip")
        }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.actions="{ item, index }">
      <Actions :actions="cardActions"
        @action-selected="onActionSelected($event, 'componentActions', item, index)" />
    </template>
    <template v-slot:item.nullable="{ item }">
      <v-icon :color="setConfigIconCheckOnTables(item.nullable).color">
        {{ setConfigIconCheckOnTables(item.nullable).icon }}
      </v-icon>
    </template>
    <template v-slot:item.position="{ item }">
      {{ item.position + 1 }}
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td v-if="hasDraggableHeader"></td>
      <td
        :colspan="headers.length - (hasDraggableHeader + 1)"
        class="flex justify-content-center flex-wrap pl-4"
      >
        <extra-information :item="item" :dataOfRequests="dataOfRequests"></extra-information>
      </td>
      <td></td>
    </template>
    <template v-slot:item.name="{ item }">
      {{ item.name }}
    </template>
    <template v-slot:item.showInFinalReport="{ item }">
      <v-icon :color="setConfigIconCheckOnTables(item.displayColumn).color">
        {{ setConfigIconCheckOnTables(item.displayColumn).icon }}
      </v-icon>
    </template>
    <template v-slot:item.assignedValue="{ item }">
      <span v-if="setAssignedValue(item)">{{ $t(setAssignedValue(item)) }}</span>
      <v-tooltip v-else bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" color="var(--orange)">mdi-alert</v-icon>
        </template>
        <span>{{ $t('noValueAssignedColumn') }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.conditions="{ item }">
      <div class="d-flex flex-wrap my-1">
        <v-chip
          v-for="(condition, index) in item.conditions"
          :key="index + condition.initialColumnName"
          class="mr-2 my-1"
          style="color: var(--fontColor); padding: 0 8px"
        >
          {{ formatCondition(condition) }}
        </v-chip>
      </div>
    </template>
    <template v-slot:item.alias="{ item }">
      <span>
        {{ item?.alias || '--' }}
      </span>
    </template>
    <template v-slot:item.outputAlias="{ item }">
      <span>
        {{ item?.alias || '--' }}
      </span>
    </template>
    <template v-slot:item.columnReferenceColumnName="{ item }">
      <span>
        {{ item?.columnReference?.option === 'INPUT_COLUMN_NAME' ? item?.columnReference?.value : '--' }}
      </span>
    </template>
    <template v-slot:item.rows="{ index }">
      <span>
        {{ `${$t('row')} ${index + 1}` }}
      </span>
    </template>
    <template
      v-for="(headerName, index) in columnsList"
      v-slot:[`header.${headerName.value}`]="{ header }"
    >
      <span :key="index">
        <quick-actions
          :valueToShow="header.text"
          :actions="cellActions.filter(({ headerAction }) => headerAction)"
          @onActionSelected="onActionSelected($event, 'cellActions', header)"
        />
      </span>
    </template>
    <template
      v-for="(headerName, index) in columnsList"
      v-slot:[`item.${headerName.value}`]="{ item, index: rowIndex }"
    >
      <span :key="index">
        <quick-actions
          :valueToShow="getDatatoShow(item, headerName.value)"
          :actions="cellActions.filter(({ headerAction, emptyValue }) => !headerAction)"
          @onActionSelected="onActionSelected($event, 'cellActions', item[headerName.value], rowIndex)"
        />
        <v-dialog
          :key="headerName.value + index + rowIndex"
          v-model="dialogItemsOpen[`${headerName.value}${rowIndex}`]"
          light
          :overlay-opacity="0.25"
          :overlay-color="'rgba(0, 0, 0)'"
          content-class="dialog-cell"
          :max-width="!($vuetify.breakpoint.width < 800) ? '60%' : '100%'"
          scrollable
        >
          <v-card class="dialog-card" elevation="0">
            <v-card-title>
              <span class="dialog-title">
                {{ $t('rowColumnInformation', { index: rowIndex + 1, column: headerName.value }) }}
              </span>
            </v-card-title>
            <v-card-text>
              <span v-if="item[headerName.value] && !item[headerName.value].conditionals"
                class="dialog-subtitle">
                {{ $t('valueAssigned', { item: $t(setAssignedValue(item[headerName.value])) }) }}
              </span>
              <extra-information :item="item[headerName.value]"
                :dataOfRequests="dataOfRequests"></extra-information>
            </v-card-text>
            <v-card-actions>
              <v-btn
                depressed
                rounded
                outlined
                class="cancelButton"
                @click.stop="dialogItemsOpen[`${headerName.value}${rowIndex}`] = false"
              >
                <v-icon left>mdi-close</v-icon>
                {{ $t('close') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </span>
    </template>

  </v-data-table>
  <div v-else-if="searching">
    <NoData :text="configNodata.text" :image="configNodata.image" :hasCard="false" class="pb-3" />
  </div>
</div>
</template>

<script>
import ExtraInformation from './ExtraInformation.vue';
import QuickActions from './QuickActions.vue';
import NoData from "../views/common/NoData.vue";
import DataTableSkeleton from '../skeletonComponents/DataTableSkeleton.vue';
import { tools } from "@/mixins/tools";
import { mapState } from "vuex";
import i18n from "@/plugins/i18n";
import Sortable from 'sortablejs';

export default {
  name: "DetailTableFrame",
  components: {
    ExtraInformation,
    QuickActions,
    NoData,
    DataTableSkeleton,
  },
  mixins: [tools],
  props: {
    dragAndDrop: {
      type: Boolean,
      default: false,
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      required: true,
    },
    dataColumns: {
      type: Array,
    },
    searching: {
      type: Boolean,
      default: false,
    },
    dataOfRequests: {
      type: Array,
      required: true,
    },
    isDialogOpen: {
      type: Object,
      required: true,
    },
    loadingParent: {
      type: Boolean,
      default: false,
    },
    dragging: {
      type: Boolean,
      default: false,
    },
    configNodata: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expandedItems: [],
      dialogItemsOpen: {},
    };
  },
  computed: {
    ...mapState([
      "actualView",
      "envTheme",
      "loadingData",
    ]),
    cardActions() {
      return this.setActions(this.actualView.componentActions);
    },
    cellActions() {
      let actions = this.actualView.cellActions
      return this.setActions(actions)
    },
    hasDraggableHeader() {
      return this.headers.filter(({ value }) => value === 'draggable').length
    },
    columnsList() {
      return this.actualView.tableDetail.dynamicHeaders && this.headers.filter(h => h.value && !['rows', 'actions'].includes(h.value))
    },
    numColumnsSkeleton(){
      return  this.$route.name === 'columnsIn' ||  this.$route.name === 'columnsOut' ? 4 : 2;
    }
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        if (binding.value && binding.value) {
          const options = {
            animation: 150,
            onUpdate: function (event) {
              vnode.child.$emit("sorted", event);
            },
          };
          Sortable.create(el.getElementsByTagName("tbody")[0], options);
        } else return false;
      },
    },
  },
  watch: {
    expanded: {
      handler(val) {
        this.expandedItems = val;
      },
      deep: true,
    },
    isDialogOpen: {
      handler(val) {
        this.dialogItemsOpen = val;
      },
      deep: true,
    },
  },
  methods: {
    onActionSelected(action, type, item, index) {
      this.$emit('action-selected', [action, type, item, index]);
    },
    setAssignedValue(item) {
      let value;
      if (item.conditionals && item.conditionals !== '--') value = 'eachCase'
      else value = ['addTimeStepTransformation', 'dictionaryTransformation', 'columnReference', 'joinColumns', "arithmeticTransformation", 'substringTransformation']
        .map(el => item[el]
          ? el !== 'columnReference'
            ? el === 'joinColumns'
              ? item[el].length && el
              : el
            : item[el]['option']
          : null).filter(Boolean)[0]
      return value
    },
    customSort(items, sortBy, sortDesc) {
      const attribute = sortBy[0];
      const isDesc = sortDesc[0];
      const isDescMult = (isDesc ? -1 : 1)
      let attr;
      let valueA;
      let valueB;

      items.sort((a, b) => {
        switch (attribute) {
          case 'columnReferenceColumnName':
            valueA = a.columnReference?.value;
            valueB = b.columnReference?.value;
            break;
          case 'assignedValue':
            valueA = i18n.t(this.setAssignedValue(a));
            valueB = i18n.t(this.setAssignedValue(b));
            break;
          default:
            attr = this.getElement(attribute);
            valueA = a[attr];
            valueB = b[attr];
        }
        if (valueA === null || valueA === undefined) return 1 * isDescMult;
        if (valueB === null || valueB === undefined) return -1 * isDescMult;
        if (typeof valueB === 'string' && typeof valueB === 'string') {
          valueA = valueA.toLowerCase();
          valueB = valueB.toLowerCase();
        }
        if (valueA !== valueB) {
          return (valueA < valueB ? -1 : 1) * isDescMult;
        }
        return 0;
      });

      return items;
    },
    onSorted(event) {
      this.$emit('save-order', event);
    },
    onRowClick(_, extradata) {
      if (this.dragAndDrop || !this.showExpand) return;
      extradata.expand(!extradata.isExpanded);
    },
    getElement(header) {
      if (header === 'outputAlias') return 'alias';
      if (header === 'showInFinalReport') return 'displayColumn';
      return header;
    },
    getDatatoShow(item, name) {
      const valueToShow = item[name] && this.setAssignedValue(item[name]);

      return valueToShow && i18n.t(valueToShow)
    },
    formatCondition({ initialColumnName, comparisonOperator, endValueReference }) {
      const formatedOperatorMap = {
        NOT_EMPTY: i18n.t("NOT_EMPTY"),
        EMPTY: i18n.t("EMPTY"),
        EQUAL_TO: "=",
        NOT_EQUAL: "!=",
        GREATER_THAN: ">",
        LESS_THAN: "<",
        GREATER_THAN_OR_EQUAL_TO: ">=",
        LESS_THAN_OR_EQUAL_TO: "<=",
        REGULAR_EXPRESSION: i18n.t("REGULAR_EXPRESSION")
      };
      return `${initialColumnName?.value} ${formatedOperatorMap[comparisonOperator]} ${endValueReference?.value
          ? endValueReference?.value
          : !['NOT_EMPTY', 'EMPTY'].includes(comparisonOperator)
            ? "--"
            : ""
        }`;
    },
  }
}
</script>

<style scoped lang="scss">

.table-detail {
  border: 1px solid var(--borderGray);
  border-radius: 10px;
  max-height: calc(100vh - 426px) !important;
  overflow: auto;

  ::v-deep th[role=columnheader], ::v-deep td {
    text-wrap: nowrap;
  }

  &.row-not-expansible {
    ::v-deep tr {
      cursor: default;
    }
  }

  &.dynamic-headers {
    ::v-deep {

      tbody>tr>td:not(:last-child),
      thead>tr>th:not(:last-child) {
        padding-right: 40px;

        >span {
          white-space: nowrap;
        }
      }
    }
  }
}
.modal-wrapper {
  .table-detail {
    max-height: calc(100vh - 220px) !important;
  }
  max-height: calc(100vh - 128px) !important;
}


.fixed-header-table {
  display: flex;
}
::v-deep {
  .modifyPositionAction .v-btn--disabled .v-btn__content {
    color: var(--fontColorTerciary);

    .v-icon {
      color: var(--fontColorTerciary) !important;
    }
  }

  .modifyPositionAction .v-btn__content {
    color: var(--gray);
  }

  .toogle {
    margin: 0px;
    padding: 0px;
    height: 20px;
  }

  th {
    color: var(--fontColorTerciary) !important;
    font-size: 12px;
    font-weight: 400;
  }
}

.v-data-table__expanded__content {
  td {
    overflow: auto;
    padding: 1rem !important;
    background: var(--bgGray);
  }
}

table {
  color: var(--fontColor) !important;
}

::v-deep {
  tr {
    color: var(--fontColor);
  }
}

.v-speed-dial.v-speed-dial--direction-right {
  display: inline;
  cursor: pointer;

  ::v-deep {
    .v-speed-dial__list {
      padding: 0 0;
      z-index: 3
    }
  }
}

::v-deep .v-dialog.dialog-cell {
  border-radius: 10px;
  box-shadow: none;
  border-radius: 10px;

  .dialog-card {
    .v-card__title {
      padding: 20px 20px 0px 20px;
    }

    .v-card__text {
      padding: 0px 20px;
    }

    .v-card__actions {
      padding: 20px;
    }

    .dialog-title {
      font-size: 20px;
      font-weight: 400;
      padding-bottom: 25px;
      display: block;
    }

    .dialog-subtitle {
      display: block;
      font-size: 16px;
      color: #2F8E98;
      font-weight: 500;
      padding-bottom: 25px;
    }

    .cancelButton {
      border-color: var(--borderGray);
      color: var(--fontColorSecondary);
    }
  }
}

::v-deep .v-data-table__wrapper {
  width: 100%;
}

@media screen and (min-width: 768px) {
  ::v-deep tr {
    cursor: pointer;
  }
  ::v-deep tr:hover td:has(.mdi-chevron-down):last-child,
  ::v-deep tr:hover td:has(.actions-wrp):last-child, 
  ::v-deep tr:hover td:has(.actions-wrp):nth-last-child(2) {
    background-color: #eee;
  }
  ::v-deep table:has(tr td:last-child .mdi-chevron-down) th:last-child,
  ::v-deep table:has(tr td:last-child .actions-wrp) th:last-child,
  ::v-deep table:has(tr td:nth-last-child(2) .actions-wrp) th:nth-last-child(2),
  ::v-deep tr td:has(.mdi-chevron-down):last-child,
  ::v-deep tr td:has(.actions-wrp):last-child, 
  ::v-deep tr td:has(.actions-wrp):nth-last-child(2)  {
    background-color: white;
    position: sticky;
  }
  ::v-deep table:has(tr td:last-child .mdi-chevron-down) th:last-child,
  ::v-deep table:has(tr td:last-child .actions-wrp) th:last-child,
  ::v-deep table:has(tr td:nth-last-child(2) .actions-wrp) th:nth-last-child(2) {
    z-index: 2;
  }
  ::v-deep tr td:has(.mdi-chevron-down):last-child,
  ::v-deep tr td:has(.actions-wrp):last-child,
  ::v-deep tr td:has(.actions-wrp):nth-last-child(2) {
    z-index: 1;
  }

  ::v-deep table:has(tr td:last-child .mdi-chevron-down) th:last-child,
  ::v-deep table:has(tr td:last-child .actions-wrp) th:last-child,
  ::v-deep tr td:has(.mdi-chevron-down):last-child,
  ::v-deep tr td:has(.actions-wrp):last-child {
    right: 0;
  }
  ::v-deep table:has(tr td:nth-last-child(2) .actions-wrp) th:nth-last-child(2),
  ::v-deep tr td:has(.actions-wrp):nth-last-child(2) {
    right: 56px;
  }
}
</style>