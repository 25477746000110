<template>
  <Card>
    <CardHeader class="card-header">
      {{ title }}
      <template v-slot:afterHeader v-if="actions.length">
        <Actions
          :actions="actions"
          @action-selected="selectedAction"
        />
      </template>
    </CardHeader>
    <CardContent class="card-content-table">
      <template v-slot:default>
        <slot name="alerts"></slot>
        <SearchEngine
          v-if="actualView.search && ((dataColumns && dataColumns.length) || searching)"
          :key="toSearchKey"
          :toSearch="toSearch"
          :autofocus="false"
          class="mb-7 flex-grow-1"
          @search="onSearch"
          :results="numberFilteredResults"
          :fieldToSearchWhenWriteOnInput="!!toSearch.fields[0].field && toSearch.fields[0].field"
          :asyncDataSearch="true"
        />
        <slot></slot>
      </template>
    </CardContent>
  </Card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: 'CardFrame',
  props: {
    title: {
      type: String,
      required: true,
    },
    actions: {
      type: Array,
      default: () => [],
    },
   dataColumns: {
      type: Array,
    },
     toSearch: {
      type: Object,
      required: true,
    },
    numberFilteredResults: {
      type: Number,
      required: true,
    },
    toSearchKey: {
      type: Number,
      required: true,
    },
    searching: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["actualView"]),
  },
  methods: {
    selectedAction(action) {
      this.$emit('selectedAction', action);
    },
    onSearch(event) {
      this.$emit('search', event);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-header {
  font-size: 18px;
}

.card-content-table {
  padding: 0 16px 16px 16px !important;
  color: var(--fontColor) !important;
}
</style>